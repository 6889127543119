import React from 'react';
import logo from './mechanic.svg';
import enginerIcon from './assets/enginer.svg';
import logoAGT from './assets/logo-agt.png';
import './MaintenancePage.css';

function MaintenancePage() {
  return (
    <div className="main">
        <div className="main-logo">
            <img src={logoAGT}></img>
        </div>
        <div className="card">
            <img src={enginerIcon} alt="Icone"></img>
            <h1>Site em manutenção</h1>
            <p>Nossos AgilityBOTs estão trabalhando para apresentação de uma nova experiencia para nossos usuários.</p>
        </div>
        <div className="main-button">
            <button onClick={() => {window.location.href = "https://api.whatsapp.com/send?phone=5584981132007"}} type="button">Contate-nos</button>
        </div>
    </div>
  );
}

export default MaintenancePage;
